<template src="./createVoucherWithDigitalPass.html" lang=""></template>
<script>
import { TabStrip, TabStripTab } from "@progress/kendo-vue-layout";
import { Button } from "@progress/kendo-vue-buttons";
import { ColorPicker } from "@progress/kendo-inputs-vue-wrapper";
import { Upload } from "@progress/kendo-vue-upload";
import { Input } from "@progress/kendo-vue-inputs";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { RadioButton } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { DateTimePicker } from "@progress/kendo-vue-dateinputs";
import AppModal from "../../../../common/AppModal/AppModal.vue";
import AppSnackBar from "../../../../common/AppSnackBar/AppSnackBar.vue";
import loader from "../../../../common/AppLoader/AppLoader.vue";
import { Tooltip } from "@progress/kendo-vue-tooltip";
import logoPlaceholder from "../../../../../assets/images/logoPlaceholder-2";
import Breadcrumb from "../../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import {
  updateVoucherCampaign,
  addVoucherCampaign,
  getSingleVOucherCampaign,
} from "@/services/clubDetails/voucher.service";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardImage,
  CardActions,
  Avatar,
} from "@progress/kendo-vue-layout";
export default {
  name: "CreateVoucherWithDigitalPass",
  components: {
    Tooltip,
    tabstrip: TabStrip,
    tabstripTab: TabStripTab,
    kbutton: Button,
    "kendo-colorpicker": ColorPicker,
    upload: Upload,
    loader,
    "k-input": Input,
    numericTextBox: NumericTextBox,
    radiobutton: RadioButton,
    card: Card,
    cardHeader: CardHeader,
    cardBody: CardBody,
    cardTitle: CardTitle,
    cardImage: CardImage,
    cardActions: CardActions,
    checkbox: Checkbox,
    datePicker: DatePicker,
    avatar: Avatar,
    datetimepicker: DateTimePicker,
    AppModal,
    AppSnackBar,
    Breadcrumb,
  },

  data: function () {
    let currDate = new Date();
    currDate.setDate(currDate.getDate() + 7);
    return {
      itemCode: "allVoucher",
      moduleCode: "voucher",
      loader: false,
      voucherPrefix: "",
      logoPlaceholder: logoPlaceholder,
      barcodeType: "qrcode",
      selected: 0,
      isFromManageRecipients: this.$route.params.fromManageRecipients || false,
      voucherId: +this.$route.query.voucher_id || "",
      clubId: this.$route.params.id,
      voucherName: "",
      clubName: this.$route.query.club_name,
      voucherAmount: 100,
      voucherQuantity: 1,
      expiryDate: currDate,
      currDate: currDate,
      voucherType: 3,
      submitDisable: false,
      notifyMessage: "",
      notifyType: "",
      isNotify: false,
      buttonName: "Save Program",
      isVoucherEmailSent: false,
      month_names_short: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],

      googlePass: {
        logo: "",
        logoUrl: "",
        marchantName: this.$route.query.club_name,
        barcodeAltText: "",

        backgroundColor: "#F7F7F7",
        textColor: "#24243A",
      },
      applePass: {
        logo: "",
        logoUrl: "",
        marchantName: this.$route.query.club_name,
        barcodeAltText: "",

        backgroundColor: "#F7F7F7",
        textColor: "#24243A",
        logoText: this.clubName,
        labelColor: "#24243A",
        primaryField1Label: "Voucher Amount",
        secondaryField1Label: "Expiry Date",
      },

      appleSideBarSelected: 0,
      applePreviewSelected: 0,
    };
  },
  computed: {},
  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
    if (this.$route.query.voucher_id) {
      this.buttonName = "Update Program";
      this.getVoucherCampaignInfo();
    }
    if (this.$route.query.voucher_name) {
      this.voucherName = this.$route.query.voucher_name;
    }
  },
  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    isPastDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      return date < today;
    },
    getVoucherCampaignInfo() {
      this.loader = true;
      const payload = {
        voucherCampaignId: this.voucherId,
        clubId: this.clubId,
      };
      getSingleVOucherCampaign(payload)
        .then((response) => {
          this.loader = false;
          ;
          this.dataPushMethodForUpdateVouher(response?.data?.data);
        })
        .catch((error) => {
          this.loader = false;
          this.submitDisable = false;
          this.showNotification("Something went wrong.", "error");
          ;
        });
    },

    dataPushMethodForUpdateVouher(voucher) {
      const {
        clubName,
        name,
        defaultQuantityPerAudience,
        expiryDate,
        voucherAmount,
        barcodeType,
        appleLogoUrl,
        appleMerchantName,
        // appleBarcodeAltText,
        // googleBarcodeAltText,
        googleLogoUrl,
        googleMerchantName,
        voucherPrefix,
        isVoucherEmailSent,
      } = voucher;
      this.voucherName = name;
      this.voucherAmount = voucherAmount;
      this.voucherQuantity = defaultQuantityPerAudience;
      this.expiryDate = new Date(expiryDate);
      this.barcodeType = barcodeType;
      this.googlePass.logo = googleLogoUrl;
      this.googlePass.marchantName = googleMerchantName;
      // this.googlePass.barcodeAltText = googleBarcodeAltText;
      this.applePass.logo = appleLogoUrl;
      this.applePass.marchantName = appleMerchantName;
      // this.applePass.barcodeAltText = appleBarcodeAltText;
      this.clubName = clubName;
      this.voucherPrefix = voucherPrefix ? voucherPrefix.replace(/ /g, "") : "";
      this.isVoucherEmailSent = isVoucherEmailSent
        ? isVoucherEmailSent
        : this.isVoucherEmailSent;
    },
    handleGoogleLogoUpload(e) {
      const fileType = e.target.files[0].type;
      if (
        fileType === "image/png" ||
        fileType === "image/jpeg" ||
        fileType === "image/jpg"
      ) {
        const image = e.target.files[0];
        const size = (image.size / 1024 / 1024).toFixed(2);
        if (size < 0.1) {
          const reader = new FileReader();
          reader.readAsDataURL(image);
          reader.onload = (e) => {
            this.googlePass.logoUrl = e.target.result;
          };
        } else {
          this.showNotification(
            "Maximum size upload limit is 100kb.",
            "warning"
          );
        }
      } else {
        this.showNotification("Please upload valid image.", "warning");
      }
    },
    handleAppleLogoUpload(e) {
      const image = e.target.files[0];
      const size = (image.size / 1024 / 1024).toFixed(2);
      if (size < 0.1) {
        const reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onload = (e) => {
          this.applePass.logoUrl = e.target.result;
        };
      } else {
        this.showNotification("Maximum size upload limit is 100kb.", "warning");
      }
    },
    removeGoogleImages() {
      this.googlePass.logo = "";
      this.googlePass.logoUrl = "";
    },
    removeAppleImages() {
      this.applePass.logo = "";
      this.applePass.logoUrl = "";
    },
    onSelect(e) {
      this.selected = e.selected;
    },

    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        4000
      );
    },

    navigateToVoucherHomepage() {
      this.$router.push({
        name: "AllVoucher",
        query: {
          type: "3",
        },
      });
    },
    navigateToManageRecipients() {
      this.$router.push({
        name: "VoucherManageRecipients",
        query: {
          voucherCampaignId: +this.$route.query.voucher_id,
          club_name: this.$route.query.club_name,
          type: +this.$route.query.type,
        },
        params: {
          voucherCampaignId: +this.$route.query.voucher_id,
        },
      });
    },

    cancelButtonHandler() {
      if (this.isFromManageRecipients) {
        this.navigateToManageRecipients();
      } else {
        this.navigateToVoucherHomepage();
      }
    },

    barcodeHandleChange(e) {
      this.barcodeType = e.value;
    },

    updateExpiryDate(event) {
      this.expiryDate = event.target.value;
    },

    dataValidation() {
      // ;
      // ;

      function onlyLettersAndNumbers(str) {
        return /^[A-Za-z0-9]*$/.test(str);
      }

      function containOnlySpaces(str) {
        return str.trim().length === 0;
      }

      if (!this.voucherAmount) {
        this.showNotification("Voucher amount is required.", "warning");
        return false;
      }
      if (this.isPastDate(this.expiryDate)) {
        this.showNotification("Invalid voucher expiry date.", "warning");
        return false;
      }
      if (!this.expiryDate) {
        this.showNotification("Voucher expiry date is required.", "warning");
        return false;
      }

      if (this.voucherPrefix && !onlyLettersAndNumbers(this.voucherPrefix)) {
        this.showNotification(
          "Voucher prefix should contain only letters and numbers.",
          "warning"
        );
        return false;
      }

      if (this.voucherPrefix.replace(/ /g, "").length > 6) {
        this.showNotification(
          "Voucher prefix should not be more than 6 characters.",
          "warning"
        );
        return false;
      }

      if (!this.googlePass.marchantName) {
        this.showNotification(
          " Merchant/Issuer name missing in Google pass. ",
          "warning"
        );
        return false;
      }

      if (
        this.googlePass.marchantName &&
        containOnlySpaces(this.googlePass.marchantName)
      ) {
        this.showNotification(
          "You can not enter only spaces in Merchant/Issuer name in Google pass.",
          "warning"
        );
        return false;
      }

      if (!this.applePass.marchantName) {
        this.showNotification(
          " Merchant/Issuer name missing in Apple pass. ",
          "warning"
        );
        return false;
      }

      if (
        this.applePass.marchantName &&
        containOnlySpaces(this.applePass.marchantName)
      ) {
        this.showNotification(
          "You can not enter only spaces in Merchant/Issuer name in Apple pass.",
          "warning"
        );
        return false;
      }

      if (this.googlePass.marchantName.length > 75) {
        this.showNotification(
          "Merchant/Issuer name should not be more than 75 characters.(Google Pass) ",
          "warning"
        );
        return false;
      }

      if (this.applePass.marchantName.length > 75) {
        this.showNotification(
          "Merchant/Issuer name should not be more than 75 characters.(Apple Pass) ",
          "warning"
        );
        return false;
      }

      if (!this.googlePass.logoUrl && !this.googlePass.logo) {
        this.showNotification(
          "Please upload logo in both Google and Apple pass.",
          "warning"
        );
        return false;
      }

      // if (
      //   this.googlePass.barcodeAltText &&
      //   containOnlySpaces(this.googlePass.barcodeAltText)
      // ) {
      //   this.showNotification(
      //     "Your can't input only spaces in barcode alt text in Google pass.",
      //     "warning"
      //   );
      //   return false;
      // }

      // if (
      //   this.applePass.barcodeAltText &&
      //   containOnlySpaces(this.applePass.barcodeAltText)
      // ) {
      //   this.showNotification(
      //     "Your can't input only spaces in barcode alt text in Apple pass.",
      //     "warning"
      //   );
      //   return false;
      // }

      // }

      return true;
    },

    GetFormattedDate() {
      const month = this.expiryDate.getMonth() + 1;
      const day = this.expiryDate.getDate();
      const year = this.expiryDate.getFullYear();
      return day + "-" + this.month_names_short?.[month - 1] + "-" + year;
    },

    preparePayloadForVoucherCreate() {
      return {
        name: this.voucherName,
        voucherAmount: this.voucherAmount,
        // voucherQuantity: this.voucherQuantity,
        voucherPrefix: this.voucherPrefix.replace(/ /g, ""),
        expiryDate: this.expiryDate || this.currDate,
        voucherType: this.voucherType,
        clubId: this.$route.params.id,
        barcodeType: this.barcodeType,
        GoogleLogoBase64: this.googlePass.logoUrl
          ? this.googlePass.logoUrl
          : "",
        googleMerchantName: this.googlePass.marchantName,
        // googleBarcodeAltText: this.googlePass.barcodeAltText,
        appleLogoBase64: this.googlePass.logoUrl ? this.googlePass.logoUrl : "",
        appleMerchantName: this.applePass.marchantName,
        // appleBarcodeAltText: this.applePass.barcodeAltText,
      };
    },

    preparePayloadForVoucherUpdate() {
      const payload = {
        voucherCampaignId: this.voucherId,
        voucherAmount: this.voucherAmount,
        // voucherQuantity: this.voucherQuantity,
        expiryDate: this.expiryDate,
        voucherType: this.voucherType,
        clubId: this.$route.params.id,
        barcodeType: this.barcodeType,
        GoogleLogoBase64: this.googlePass.logoUrl
          ? this.googlePass.logoUrl
          : "",
        googleMerchantName: this.googlePass.marchantName,
        // googleBarcodeAltText: this.googlePass.barcodeAltText,
        appleLogoBase64: this.googlePass.logoUrl ? this.googlePass.logoUrl : "",
        appleMerchantName: this.applePass.marchantName,
        // appleBarcodeAltText: this.applePass.barcodeAltText,
      };

      if (!this.isVoucherEmailSent) {
        payload.voucherPrefix = this.voucherPrefix.replace(/ /g, "");
      }
      return payload;
    },

    saveVoucher() {
      // ;

      // return;
      if (this.voucherId) {
        if (this.dataValidation()) {
          this.loader = true;
          this.submitDisable = true;
          let payload = this.preparePayloadForVoucherUpdate();
          updateVoucherCampaign(payload)
            .then(() => {
              this.loader = false;
              this.submitDisable = false;
              this.showNotification(
                "Voucher program updated successfully.",
                "success"
              );
              setTimeout(() => {
                this.cancelButtonHandler();
              }, 2000);
            })
            .catch((error) => {
              this.loader = false;
              this.submitDisable = false;
              this.showNotification(
                "Failed to update the voucher program.",
                "error"
              );
              ;
            });
        }
      } else {
        if (this.dataValidation()) {
          this.loader = true;
          this.submitDisable = true;
          let payload = this.preparePayloadForVoucherCreate();
          addVoucherCampaign(payload)
            .then(() => {
              this.loader = false;
              this.submitDisable = false;
              this.showNotification(
                "Voucher program created successfully.",
                "success"
              );
              setTimeout(() => {
                this.navigateToVoucherHomepage();
              }, 2000);
            })
            .catch((error) => {
              this.loader = false;
              this.submitDisable = false;
              this.showNotification(
                "Failed to save the voucher program.",
                "error"
              );
              ;
            });
        }
      }
    },
  },
};
</script>
<style lang=""></style>
