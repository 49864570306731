<loader v-if="loader" :size="'large'" :type="'converging-spinner'" />
<Breadcrumb :id="$route.params.id" />
<AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>

<div class="max-height-100--60 overflow-y-auto">
    <div class="dashboard-header d-flex flex-wrap justify-content-between align-items-end pb-1">
        <div class="d-flex align-items-center justify-content-between">
            <h2 class="font-28 font-34-sm-big font-w-300 me-3">
                {{voucherName}}
            </h2>
            <!-- <kbutton :class="'d-md-none d-inline'" :fill-mode="'flat'" @click="cancelButtonHandler">Cancel</kbutton> -->
        </div>
        <div class="">
            <kbutton :class="'me-2 d-md-inline d-none'" :fill-mode="'flat'" @click="cancelButtonHandler">Cancel</kbutton>
            <kbutton v-if="hasCreateAccess() || hasEditAccess()" :class="''" :disabled="isVoucherEmailSent" :theme-color="'primary'" @click="saveVoucher">{{buttonName}}</kbutton>
        </div>
    </div>
    <div class="event-ticket-container">
        <div class="dashboard-content p-0">
            <tabstrip :selected="selected" @select="onSelect" :class="'border-tab create-digital-top-tab'">

                <tabstripTab :title="'Basic Information'">
                    <!-- < Basic Information Settings /> -->
                    <div class="create-voucher-wrap d-flex justify-content-between">
                        <div class="w-50-p">
                            <div class="row m-0">
                                <div class="col-md-12 p-0">

                                    <div class="pass-left-content 2">
                                        <div class="column max-w-620">
                                            <div v-if="isVoucherEmailSent"
                                                class="wraning-msg-with-icon d-flex justify-content-start p-17">
                                                <div class="min-w-22">
                                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
                                                            stroke="#721C24" stroke-width="1.5" />
                                                        <path d="M7.5 4.25V8.15M7.5 10.425V10.75" stroke="#721C24"
                                                            stroke-width="1.5" stroke-linecap="round" />
                                                    </svg>
                                                </div>
                                                <p class="text-color-red-1 font-14 mb-0">You can't edit this voucher as it's
                                                    already in use by one or more members.</p>
                                            </div>

                                            <br>

                                            <div class="d-flex mb-3">
                                                <span class="min-w-24 me-2 mt-1">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M22.5 12C22.5 17.7984 17.7984 22.5 12 22.5C6.20156 22.5 1.5 17.7984 1.5 12C1.5 6.20156 6.20156 1.5 12 1.5C17.7984 1.5 22.5 6.20156 22.5 12ZM21 12C21 7.03125 16.9688 3 12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12ZM13.5 9V6H10.5V9H13.5ZM13.5 16.5H15V18H9V16.5H10.5V12H9V10.5H13.5V16.5Z"
                                                            fill="#0052CC" />
                                                    </svg>
                                                </span>
                                                <p class="font-15 l-height-24">Default Voucher Amount, Default Prefix, and
                                                    Default Expiry Date are default values that will be pre-filled when you
                                                    add a voucher. You can always change this information while adding
                                                    vouchers in Manage Vouchers section.</p>
                                            </div>
                                        </div>

                                        <div class="column max-w-326 min-w-100-vsm">
                                            <div class="col-md-12">
                                                <label for="">Default Voucher Amount *</label>
                                                <numericTextBox :format="'n2'" v-model="voucherAmount" :min="1" :max="500">
                                                </numericTextBox>
                                            </div>

                                            <!-- <div class="col-md-12">
                                                <label for="">Default Voucher Quantity Per Audience</label>
                                                <numericTextBox v-model="voucherQuantity" :min="1">
                                                </numericTextBox>
                                            </div> -->

                                            <div class="col-md-12">
                                                <label for=""> Default Prefix</label>
                                                <k-input :disabled="isVoucherEmailSent" type="text"
                                                    placeholder=" Default Prefix" v-model="voucherPrefix"></k-input>
                                            </div>

                                            <p class="font-19">Voucher Duration</p>
                                            <div class="col-md-12">
                                                <label for="">Default Expiry Date *</label>
                                                <datePicker :format="'dd-MMM-yyyy'" :default-value="currDate"
                                                    @change="updateExpiryDate" :min="new Date()" :value="expiryDate">
                                                </datePicker>
                                            </div>
                                        </div>

                                        <h4 class="font-19">Voucher Barcode</h4>
                                        <br>
                                        <div class="d-flex mb-32 flex-wrap">
                                            <div class="d-flex flex-column text-center mr-32 mr-10-vsm mb-2">
                                                <avatar class="avatar-lg-2 border-gray bg-white" :border="true"
                                                    :fill-mode="'outline'" :theme-color="success">
                                                    <img src="../../../../../assets/images/barcode.png" alt="" />
                                                </avatar>
                                                <label for="" class="mb-0 mt-2">QR CODE</label>
                                                <radiobutton :size="'large'" :name="'barcodeGroup'" :value="'qrcode'"
                                                    :checked="barcodeType === 'qrcode'" @change="barcodeHandleChange"
                                                    :label="''" />
                                            </div>

                                            <div class="d-flex flex-column text-center mr-32 mr-10-vsm mb-2">
                                                <avatar class="avatar-lg-2 border-gray bg-white" :border="true"
                                                    :fill-mode="'outline'" :theme-color="success">
                                                    <img src="../../../../../assets/images/aztec.png" alt="" />
                                                </avatar>
                                                <label for="" class="mb-0 mt-2">AZTEC</label>
                                                <radiobutton :size="'large'" :name="'barcodeGroup'" :value="'aztec'"
                                                    :checked="barcodeType === 'aztec'" @change="barcodeHandleChange"
                                                    :label="''" />
                                            </div>

                                            <div class="d-flex flex-column text-center mr-32 mr-10-vsm mb-2">
                                                <avatar class="avatar-lg-2 border-gray bg-white" :border="true"
                                                    :fill-mode="'outline'" :theme-color="success">
                                                    <img src="../../../../../assets/images/pdf.png" alt="" />
                                                </avatar>
                                                <label for="" class="mb-0 mt-2">PDF 417</label>
                                                <radiobutton :size="'large'" :name="'barcodeGroup'" :value="'pdf417'"
                                                    :checked="barcodeType === 'pdf417'" @change="barcodeHandleChange"
                                                    :label="''" />
                                            </div>

                                            <div class="d-flex flex-column text-center mr-32 mr-10-vsm mb-2">
                                                <avatar class="avatar-lg-2 border-gray bg-white" :border="true"
                                                    :fill-mode="'outline'" :theme-color="success">
                                                    <img src="../../../../../assets/images/code128.png" alt="" />
                                                </avatar>
                                                <label for="" class="mb-0 mt-2">CODE 128</label>
                                                <radiobutton :size="'large'" :name="'barcodeGroup'" :value="'code128'"
                                                    :checked="barcodeType === 'code128'" @change="barcodeHandleChange"
                                                    :label="''" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-50-p">
                            <div class="create-voucher-right p-44 h-100 py-3">
                                <div v-if="this.creationTypeSerial != 4"
                                    class="create-voucher-content-single p-42 bg-white ">
                                    <div class="font-16">
                                        Hi,
                                        <br><br>
                                        Congratulations! You have received a gift voucher worth ${{voucherAmount}}. <br><br>
                                        This gift voucher has been sent to you by club, {{clubName}}.
                                        Use the redemption code provided below while purchasing in stores to redeem this
                                        gift. You can also show the barcode to the representative at the cash counter.
                                        Redeem your voucher before {{GetFormattedDate()}} to get exciting discounts on your
                                        purchases.
                                        <br><br>
                                        Voucher Code : <strong> {{voucherPrefix.slice(0,6).replace(/ /g, "") +
                                            '********************'
                                            }}</strong>
                                        <br>
                                        <div v-if="barcodeType == 'qrcode'">
                                            <br>
                                            <div class="qr-icon">
                                                <img src="../../../../../assets/images/barcode-large.png" alt="" />
                                            </div>
                                        </div>
                                        <div v-if="barcodeType == 'aztec'">
                                            <br>
                                            <div class="qr-icon">
                                                <img src="../../../../../assets/images/aztec-large.png" alt="" />
                                            </div>
                                        </div>
                                        <div v-if="barcodeType == 'pdf417'">
                                            <br>
                                            <div class="qr-icon">
                                                <img src="../../../../../assets/images/pdf417-large.png" alt="" />
                                            </div>
                                        </div>
                                        <div v-if="barcodeType == 'code128'">
                                            <br>
                                            <div class="qr-icon">
                                                <img src="../../../../../assets/images/code128-large.png" alt="" />
                                            </div>
                                        </div>
                                        <br>
                                        Best wishes,
                                        <br>
                                        {{clubName}}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </tabstripTab>

            </tabstrip>
        </div>
    </div>
</div>