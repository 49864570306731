<loader v-if="loader" :size="'large'" :type="'converging-spinner'" />
<Breadcrumb :id="$route.params.id" />
<AppSnackBar :message="notifyMessage" :notificationType="notifyType" v-if="isNotify"></AppSnackBar>

<div class="max-height-100--60 overflow-y-auto">
    <div class="dashboard-header d-flex flex-wrap justify-content-between align-items-end pb-1">
        <div class="d-flex align-items-center justify-content-between">
            <h2 class="font-28 font-34-sm-big font-w-300 me-3">
                {{voucherName}}
            </h2>
            <!-- <kbutton :class="'md-none d-inline'" :fill-mode="'flat'" @click="cancelButtonHandler">Cancel</kbutton> -->
        </div>

        <div class="">
            <kbutton :class="'me-2 d-md-inline d-none'" :fill-mode="'flat'" @click="cancelButtonHandler">Cancel</kbutton>
            <kbutton v-if="hasCreateAccess() || hasEditAccess()" :class="''" :disabled="submitDisable || isVoucherEmailSent" :theme-color="'primary'"
                @click="saveVoucher">
                {{buttonName}}</kbutton>
        </div>
    </div>
    <div class="event-ticket-container">
        <div class="dashboard-content p-0">
            <tabstrip :selected="selected" @select="onSelect" :class="'border-tab create-digital-top-tab'">
                <tabstripTab :title="'Basic Information'">
                    <!-- < Basic Information Settings /> -->
                    <div class="create-voucher-wrap d-flex justify-content-between">
                        <div class="w-50-p">
                            <div class="row m-0">
                                <div class="col-md-12 p-0">

                                    <div class="pass-left-content 3">
                                        <div class="column max-w-620">
                                            <div v-if="isVoucherEmailSent"
                                                class="wraning-msg-with-icon d-flex justify-content-start p-17">
                                                <div class="min-w-22">
                                                    <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
                                                            stroke="#721C24" stroke-width="1.5" />
                                                        <path d="M7.5 4.25V8.15M7.5 10.425V10.75" stroke="#721C24"
                                                            stroke-width="1.5" stroke-linecap="round" />
                                                    </svg>
                                                </div>
                                                <p class="text-color-red-1 font-14 mb-0">You can't edit this voucher as it's
                                                    already in use by one or more members.</p>
                                            </div>

                                            <br>

                                            <div class="d-flex mb-3">
                                                <span class="min-w-24 me-2 mt-1">
                                                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                        xmlns="http://www.w3.org/2000/svg">
                                                        <path fill-rule="evenodd" clip-rule="evenodd"
                                                            d="M22.5 12C22.5 17.7984 17.7984 22.5 12 22.5C6.20156 22.5 1.5 17.7984 1.5 12C1.5 6.20156 6.20156 1.5 12 1.5C17.7984 1.5 22.5 6.20156 22.5 12ZM21 12C21 7.03125 16.9688 3 12 3C7.03125 3 3 7.03125 3 12C3 16.9688 7.03125 21 12 21C16.9688 21 21 16.9688 21 12ZM13.5 9V6H10.5V9H13.5ZM13.5 16.5H15V18H9V16.5H10.5V12H9V10.5H13.5V16.5Z"
                                                            fill="#0052CC" />
                                                    </svg>
                                                </span>
                                                <p class="font-15 l-height-24">Default Voucher Amount, Default Prefix, and
                                                    Default Expiry Date are default values that will be pre-filled when you
                                                    add a voucher. You can always change this information while adding
                                                    vouchers in Manage Vouchers section.</p>
                                            </div>
                                        </div>

                                        <div class="column max-w-326 min-w-100-vsm">
                                            <div class="col-md-12">
                                                <label for="">Default Voucher Amount *</label>
                                                <numericTextBox :format="'n2'" v-model="voucherAmount" :min="1" :max="500">
                                                </numericTextBox>
                                            </div>

                                            <!-- <div class="col-md-12">
                                                <label for="">Default Voucher Quantity Per Audience</label>
                                                <numericTextBox v-model="voucherQuantity" :min="1">
                                                </numericTextBox>
                                            </div> -->

                                            <div class="col-md-12">
                                                <label for=""> Default Prefix</label>
                                                <k-input :disabled="isVoucherEmailSent" type="text"
                                                    placeholder=" Default Prefix" v-model="voucherPrefix"></k-input>
                                            </div>

                                            <p class="font-19">Voucher Duration</p>
                                            <div class="col-md-12">
                                                <label for="">Default Expiry Date *</label>
                                                <datePicker :format="'dd-MMM-yyyy'" :default-value="currDate"
                                                    @change="updateExpiryDate" :min="new Date()" :value="expiryDate">
                                                </datePicker>
                                            </div>
                                        </div>

                                        <h4 class="font-19">Voucher Barcode</h4>
                                        <br>
                                        <div class="d-flex mb-32 flex-wrap">
                                            <div class="d-flex flex-column text-center mr-32 mr-10-vsm mb-2">
                                                <avatar class="avatar-lg-2 border-gray bg-white" :border="true"
                                                    :fill-mode="'outline'" :theme-color="success">
                                                    <img src="../../../../../assets/images/barcode.png" alt="" />
                                                </avatar>
                                                <label for="" class="mb-0 mt-2">QR CODE</label>
                                                <radiobutton :size="'large'" :name="'barcodeGroup'" :value="'qrcode'"
                                                    :checked="barcodeType === 'qrcode'" @change="barcodeHandleChange"
                                                    :label="''" />
                                            </div>

                                            <div class="d-flex flex-column text-center mr-32 mr-10-vsm mb-2">
                                                <avatar class="avatar-lg-2 border-gray bg-white" :border="true"
                                                    :fill-mode="'outline'" :theme-color="success">
                                                    <img src="../../../../../assets/images/aztec.png" alt="" />
                                                </avatar>
                                                <label for="" class="mb-0 mt-2">AZTEC</label>
                                                <radiobutton :size="'large'" :name="'barcodeGroup'" :value="'aztec'"
                                                    :checked="barcodeType === 'aztec'" @change="barcodeHandleChange"
                                                    :label="''" />
                                            </div>

                                            <div class="d-flex flex-column text-center mr-32 mr-10-vsm mb-2">
                                                <avatar class="avatar-lg-2 border-gray bg-white" :border="true"
                                                    :fill-mode="'outline'" :theme-color="success">
                                                    <img src="../../../../../assets/images/pdf.png" alt="" />
                                                </avatar>
                                                <label for="" class="mb-0 mt-2">PDF 417</label>
                                                <radiobutton :size="'large'" :name="'barcodeGroup'" :value="'pdf417'"
                                                    :checked="barcodeType === 'pdf417'" @change="barcodeHandleChange"
                                                    :label="''" />
                                            </div>

                                            <div class="d-flex flex-column text-center mr-32 mr-10-vsm mb-2">
                                                <avatar class="avatar-lg-2 border-gray bg-white" :border="true"
                                                    :fill-mode="'outline'" :theme-color="success">
                                                    <img src="../../../../../assets/images/code128.png" alt="" />
                                                </avatar>
                                                <label for="" class="mb-0 mt-2">CODE 128</label>
                                                <radiobutton :size="'large'" :name="'barcodeGroup'" :value="'code128'"
                                                    :checked="barcodeType === 'code128'" @change="barcodeHandleChange"
                                                    :label="''" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="w-50-p">
                            <div class="create-voucher-right p-44 h-100 py-3">
                                <div v-if="this.creationTypeSerial != 4"
                                    class="create-voucher-content-single p-42 bg-white ">
                                    <div class="font-16">
                                        Hi,
                                        <br><br>
                                        Congratulations! You have received a gift voucher worth
                                        ${{voucherAmount}}. <br><br>
                                        This gift voucher has been sent to you by club, {{clubName}}.
                                        Use the redemption code provided below while purchasing in stores to redeem this
                                        gift. You can also show the barcode to the representative at the cash counter.
                                        Redeem your voucher before {{GetFormattedDate()}} to get exciting discounts on your
                                        purchases.
                                        <br><br>
                                        Voucher Code : <strong> {{voucherPrefix.slice(0,6).replace(/ /g, "") +
                                            '********************'
                                            }}</strong>
                                        <br>
                                        <div v-if="barcodeType == 'qrcode'">
                                            <br>
                                            <div class="qr-icon">
                                                <img src="../../../../../assets/images/barcode-large.png" alt="" />
                                            </div>
                                        </div>
                                        <div v-if="barcodeType == 'aztec'">
                                            <br>
                                            <div class="qr-icon">
                                                <img src="../../../../../assets/images/aztec-large.png" alt="" />
                                            </div>
                                        </div>
                                        <div v-if="barcodeType == 'pdf417'">
                                            <br>
                                            <div class="qr-icon">
                                                <img src="../../../../../assets/images/pdf417-large.png" alt="" />
                                            </div>
                                        </div>
                                        <div v-if="barcodeType == 'code128'">
                                            <br>
                                            <div class="qr-icon">
                                                <img src="../../../../../assets/images/code128-large.png" alt="" />
                                            </div>
                                        </div>
                                        <div>
                                            <br>
                                            <p>Click the button below for your Voucher Digital Pass
                                            </p>
                                            <br>
                                            <div>
                                                <kbutton :theme-color="'primary'" @click="" :icon="''"
                                                    :class="'pointer-events-none'">
                                                    Digital Pass
                                                </kbutton>
                                                <!-- <kbutton :theme-color="'primary'" :class="'me-3'" @click="" :icon="''">
                                                    <span><svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M13.8651 5.68847C11.6244 5.68739 9.38371 5.68793 7.14306 5.68819C7.14335 6.59892 7.14196 7.50966 7.14361 8.42011C8.44125 8.41982 9.73889 8.41957 11.0363 8.42011C10.8859 9.29283 10.3566 10.0909 9.60608 10.5822C9.13427 10.8931 8.5922 11.0949 8.03334 11.1908C7.47089 11.2849 6.8897 11.2969 6.32864 11.1856C5.75809 11.0741 5.21346 10.8411 4.73834 10.5128C3.97862 9.98971 3.39883 9.22446 3.10114 8.3632C2.79674 7.48721 2.79451 6.51248 3.10253 5.63731C3.31626 5.02049 3.67199 4.4507 4.1393 3.98702C4.71572 3.40875 5.46513 2.99536 6.27255 2.82584C6.96392 2.68122 7.69294 2.70882 8.36982 2.90949C8.94514 3.08066 9.47552 3.3896 9.90993 3.79616C10.3491 3.36826 10.786 2.93792 11.2243 2.50922C11.4542 2.27847 11.6958 2.05783 11.9185 1.82079C11.2528 1.21708 10.4724 0.731799 9.61976 0.425025C8.08468 -0.128603 6.35347 -0.140354 4.80808 0.383797C3.06653 0.968068 1.58362 2.24433 0.761425 3.85747C0.475174 4.41332 0.266184 5.00661 0.140639 5.61686C-0.175198 7.13727 0.0449413 8.75835 0.760583 10.1432C1.22569 11.047 1.89249 11.8506 2.70134 12.4805C3.46442 13.0768 4.35386 13.5173 5.2969 13.7631C6.48685 14.0759 7.75351 14.0688 8.9513 13.8017C10.0338 13.5575 11.058 13.0506 11.8758 12.3108C12.7402 11.5323 13.3567 10.5068 13.6832 9.40467C14.0392 8.2025 14.0883 6.9191 13.8651 5.68847Z"
                                                                fill="white" />
                                                        </svg>
                                                    </span>
                                                    Google Pass
                                                </kbutton>
                                                <kbutton :theme-color="'primary'" :class="''" @click="" :icon="''">
                                                    <span><svg width="15" height="14" viewBox="0 0 15 14" fill="none"
                                                            xmlns="http://www.w3.org/2000/svg">
                                                            <path
                                                                d="M11.1876 7.43971C11.1705 5.66632 12.5829 4.81576 12.6461 4.77279C11.8523 3.56994 10.6156 3.4049 10.1755 3.38611C9.1239 3.27542 8.12255 4.02809 7.58906 4.02809C7.05667 4.02809 6.23241 3.40233 5.36038 3.41827C4.21323 3.43591 3.15586 4.10976 2.56553 5.17402C1.37418 7.31593 2.26076 10.4902 3.42164 12.2286C3.98918 13.0783 4.66599 14.0345 5.55395 13.9995C6.40978 13.9645 6.73268 13.4258 7.76725 13.4258C8.80183 13.4258 9.09233 13.9995 9.99758 13.9815C10.9179 13.9642 11.5014 13.1156 12.0643 12.2625C12.7158 11.2753 12.9841 10.3195 13 10.2711C12.98 10.2614 11.206 9.55714 11.1876 7.43971Z"
                                                                fill="white" />
                                                            <path
                                                                d="M9.48543 2.23524C9.95632 1.64278 10.2754 0.819541 10.1886 0C9.50904 0.0284563 8.68643 0.468106 8.19852 1.06C7.76141 1.5853 7.37921 2.42163 7.48162 3.22637C8.23971 3.28727 9.0129 2.82656 9.48543 2.23524Z"
                                                                fill="white" />
                                                        </svg>
                                                    </span>
                                                    Apple Pass
                                                </kbutton> -->
                                            </div>
                                        </div>
                                        <br>
                                        Best wishes,
                                        <br>
                                        {{clubName}}
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </tabstripTab>

                <tabstripTab :title="'Google Pass'">
                    <!-- <GooglePass /> -->
                    <div class="row m-0">
                        <div class="col-xl-8 col-lg-7 col-md-7 col-12 p-0">
                            <div class="pass-left-content">
                                <div class="column max-w-620">
                                    <div v-if="isVoucherEmailSent"
                                        class="wraning-msg-with-icon d-flex justify-content-start p-17">
                                        <div class="min-w-22">
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
                                                    stroke="#721C24" stroke-width="1.5" />
                                                <path d="M7.5 4.25V8.15M7.5 10.425V10.75" stroke="#721C24"
                                                    stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                        <p class="text-color-red-1 font-14 mb-0">You can't edit this voucher as it's
                                            already in use by one or more members.</p>
                                    </div>
                                    <br>
                                </div>
                                <h4 class="font-14 font-w-700 mb-3">Logo Image</h4>
                                <div class="file-uploader-custom">
                                    <div class="uplodade-img-box">
                                        <kbutton v-if="googlePass.logoUrl || googlePass.logo" :icon="'close'"
                                            @click="removeGoogleImages('logo')" :size="'small'"></kbutton>
                                        <img v-if="googlePass.logoUrl || googlePass.logo || logoPlaceholder"
                                            :src="googlePass.logoUrl || googlePass.logo || logoPlaceholder" />
                                        <img v-else src="../../../../../assets/images/login-bg.png" />
                                    </div>

                                    <input type="file" title="Select a file" @change="handleGoogleLogoUpload"
                                        accept="image/png, image/jpeg" />
                                </div>
                                <div class="max-w-730">
                                    <div>
                                        <label class="mt-4" for="">Marchant Name / Issuer Name *</label>
                                        <k-input type="text" v-model="googlePass.marchantName"
                                            placeholder="Type your organisation name"></k-input>
                                    </div>

                                    <div>
                                        <label class="mt-4" for="">Voucher Amount / Gift Card Balance *</label>
                                        <numericTextBox :format="'n2'" :disabled="true" v-model="voucherAmount">
                                        </numericTextBox>
                                    </div>

                                    <div>
                                        <label for="">Expiry Date *</label>
                                        <datePicker :disabled="true" :format="'dd-MMM-yyyy'" :value="expiryDate">
                                        </datePicker>
                                    </div>

                                    <!-- <div>
                                        <label class="mt-4" for="">Type your barcode alt text</label>
                                        <k-input type="text" v-model="googlePass.barcodeAltText">
                                        </k-input>
                                    </div> -->
                                </div>

                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-5 col-md-5 col-12 p-0">
                            <div class="voucher-ticket-preview-wrap">
                                <div class="pass-preview-main new-1">
                                    <div :style="{ background: googlePass.backgroundColor }" class="pass-preview">
                                        <div class="pass-header d-flex align-items-center justify-content-start mb-2 pt-20">
                                            <div class="logo h-36 w-36 me-2 ms-0">
                                                <img v-if="googlePass.logoUrl || googlePass.logo || logoPlaceholder"
                                                    :src="googlePass.logoUrl || googlePass.logo || logoPlaceholder "
                                                    alt="Logo" />
                                            </div>
                                            <div class="details me-auto">
                                                <div class="h-13 l-height-21 font-16 h-auto font-w-700">
                                                    {{ googlePass.marchantName }}
                                                </div>
                                            </div>
                                        </div>

                                        <div class="pass-title px-16 mt-3">
                                            <h3 class="font-20 mb-0 font-w-600">Voucher Card</h3>
                                        </div>

                                        <div class="pass-date-time py-0 mb-5">
                                            <div class="d-flex justify-content-between m-0">
                                                <div class="px-16 py-16" v-if="voucherAmount">
                                                    <label>Voucher Amount</label>
                                                    <div class="font-16 font-w-600">
                                                        {{ voucherAmount.toFixed(2) }}
                                                    </div>
                                                </div>
                                                <div v-if="expiryDate" class="px-16 py-16 text-right">
                                                    <label>Expiry Date</label>
                                                    <div class="font-16 font-w-600">
                                                        {{GetFormattedDate()}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="barcodeType != 'none'" class="pass-barcode">
                                            <div v-if="barcodeType === 'qrcode'" class="barcode-box">
                                                <img src="../../../../../assets/images/qrcode-for-pass.png" />
                                            </div>
                                            <div v-if="barcodeType === 'aztec'" class="barcode-box">
                                                <img src="../../../../../assets/images/aztech-for-pass.png" />
                                            </div>
                                            <div v-if="barcodeType === 'pdf417'" class="barcode-box">
                                                <img src="../../../../../assets/images/pdf417-for-pass.png" />
                                            </div>
                                            <div v-if="barcodeType === 'code128'" class="barcode-box">
                                                <img src="../../../../../assets/images/code128-for-pass.png" />
                                            </div>
                                            <!-- <div :style="{ color: applePass.textColor }"
                                                class="pass-placeholder l-height-16">
                                                {{ googlePass.barcodeAltText }}
                                            </div> -->
                                        </div>
                                        <div class="text-center mb-5">
                                            <p class="font-12 mb-0 text-uppercase">Voucher_Code</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tabstripTab>

                <tabstripTab :title="'Apple Pass'">
                    <!-- <ApplePass /> -->
                    <div class="row m-0">
                        <div class="col-xl-8 col-lg-7 col-md-7 col-12 p-0">
                            <div class="pass-left-content">
                                <div class="column max-w-620">
                                    <div v-if="isVoucherEmailSent"
                                        class="wraning-msg-with-icon d-flex justify-content-start p-17">
                                        <div class="min-w-22">
                                            <svg width="15" height="15" viewBox="0 0 15 15" fill="none"
                                                xmlns="http://www.w3.org/2000/svg">
                                                <path
                                                    d="M7.5 14C11.0899 14 14 11.0899 14 7.5C14 3.91015 11.0899 1 7.5 1C3.91015 1 1 3.91015 1 7.5C1 11.0899 3.91015 14 7.5 14Z"
                                                    stroke="#721C24" stroke-width="1.5" />
                                                <path d="M7.5 4.25V8.15M7.5 10.425V10.75" stroke="#721C24"
                                                    stroke-width="1.5" stroke-linecap="round" />
                                            </svg>
                                        </div>
                                        <p class="text-color-red-1 font-14 mb-0">You can't edit this voucher as it's
                                            already in use by one or more members.</p>
                                    </div>
                                    <br>
                                </div>
                                <h4 class="font-14 font-w-700 mb-3">Logo Image</h4>
                                <div class="file-uploader-custom">
                                    <div class="uplodade-img-box">
                                        <kbutton v-if="googlePass.logoUrl || googlePass.logo" :icon="'close'"
                                            @click="removeGoogleImages('logo')" :size="'small'"></kbutton>
                                        <img v-if="googlePass.logoUrl || googlePass.logo || logoPlaceholder"
                                            :src="googlePass.logoUrl || googlePass.logo || logoPlaceholder" />
                                        <img v-else src="../../../../../assets/images/login-bg.png" />
                                    </div>

                                    <input type="file" title="Select a file" @change="handleGoogleLogoUpload"
                                        accept="image/png, image/jpeg" />
                                </div>
                                <div class="max-w-730">
                                    <div>
                                        <label class="mt-4" for="">Marchant Name / Issuer Name *</label>
                                        <k-input type="text" v-model="applePass.marchantName"
                                            placeholder="Type your organisation name"></k-input>
                                    </div>

                                    <div>
                                        <label class="mt-4" for="">Voucher Amount / Gift Card Balance *</label>
                                        <numericTextBox :format="'n2'" :disabled="true" v-model="voucherAmount">
                                        </numericTextBox>
                                    </div>

                                    <div>
                                        <label for="">Expiry Date *</label>
                                        <datePicker :disabled="true" :format="'dd-MMM-yyyy'" :value="expiryDate">
                                        </datePicker>
                                    </div>

                                    <!-- <div>
                                        <label class="mt-4" for="">Type your barcode alt text</label>
                                        <k-input type="text" v-model="applePass.barcodeAltText">
                                        </k-input>
                                    </div> -->
                                </div>

                            </div>
                        </div>

                        <div class="col-xl-4 col-lg-5 col-md-5 col-12 p-0">
                            <div class="digital-pass-wrap-apple voucher-ticket-preview-wrap apple-pass">
                                <div class="pass-preview-main new-1">
                                    <div :style="{ background: applePass.backgroundColor }" class="pass-preview">
                                        <div class="pass-header">
                                            <div class="d-flex align-items-center mb-2">
                                                <div class="logo h-40 w-40">
                                                    <img v-if="googlePass.logoUrl || googlePass.logo || logoPlaceholder"
                                                        :src="googlePass.logoUrl || googlePass.logo || logoPlaceholder "
                                                        alt="Logo" />
                                                </div>
                                                <div class="title font-16">
                                                    <div :style="{ color: applePass.textColor }"
                                                        class="pass-placeholder h20 font-w-700">
                                                        {{ applePass.marchantName }}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="pass-title px-16 mt-1 mb-4">
                                            <label class="text-uppercase font-10 mb-0">CARD TYPE</label>
                                            <h3 class="font-20 mb-0 font-w-600">Voucher Card</h3>
                                        </div>
                                        <!--Secondary Field-->
                                        <div :class="appleThumbnailContainerClass" class="pt-1 mb-5">
                                            <div class="d-flex justify-content-between m-0 px-16">
                                                <div v-if="applePass.primaryField1Label" class="mb-3">
                                                    <label class=" font-10 l-height-12 text-uppercase h-auto"
                                                        :style="{ color: applePass.labelColor }">
                                                        {{ applePass.primaryField1Label }}
                                                    </label>
                                                    <div :style="{ color: applePass.textColor }" class="font-15 font-w-600">
                                                        {{ voucherAmount .toFixed(2) }}
                                                    </div>
                                                </div>
                                                <div v-if="applePass.secondaryField1Label" class="mb-3">
                                                    <label class=" font-10 l-height-12 text-uppercase h-auto"
                                                        :style="{ color: applePass.labelColor }">
                                                        {{ applePass.secondaryField1Label }}
                                                    </label>
                                                    <div :style="{ color: applePass.textColor }" class="font-15 font-w-600">
                                                        {{GetFormattedDate()}}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div v-if="barcodeType != 'none'" class="pass-barcode radius-5 mb-3 bg-white">
                                            <div v-if="barcodeType === 'qrcode'" class="barcode-box mb-0">
                                                <img src="../../../../../assets/images/qrcode-for-pass.png" />
                                            </div>
                                            <div v-if="barcodeType === 'aztec'" class="barcode-box mb-0">
                                                <img src="../../../../../assets/images/aztech-for-pass.png" />
                                            </div>
                                            <div v-if="barcodeType === 'pdf417'" class="barcode-box mb-0">
                                                <img src="../../../../../assets/images/pdf417-for-pass.png" />
                                            </div>
                                            <div v-if="barcodeType === 'code128'" class="barcode-box mb-0">
                                                <img src="../../../../../assets/images/code128-for-pass.png" />
                                            </div>
                                            <!-- <div style="color: #000; " class="pass-placeholder l-height-16">
                                                {{ applePass.barcodeAltText }}
                                            </div> -->
                                            <div class="text-center mb-5 pb-2">
                                                <p class="font-12 mb-0 text-uppercase">Voucher_Code</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </tabstripTab>
            </tabstrip>
        </div>
    </div>
</div>