import { GET, POST, PUT } from "@/service";

export const addVoucherCampaign = (model: any) => {
  return POST(`api/v1/Vouchers`, model);
};

export const updateVoucherCampaign = (model: any) => {
  return PUT(`api/v1/Vouchers/${model.voucherCampaignId}`, model);
};

export const getSingleVOucherCampaign = (model: any) => {
  return GET(`api/v1/Vouchers/${model.voucherCampaignId}`, {
    params: model,
  });
};
