<template src="./createVoucherWithBarcode.html" lang=""></template>
<script>
import { TabStrip, TabStripTab } from "@progress/kendo-vue-layout";
import { Button } from "@progress/kendo-vue-buttons";
import { ColorPicker } from "@progress/kendo-inputs-vue-wrapper";
import { Upload } from "@progress/kendo-vue-upload";
import { Input } from "@progress/kendo-vue-inputs";
import { NumericTextBox } from "@progress/kendo-vue-inputs";
import { RadioButton } from "@progress/kendo-vue-inputs";
import { Checkbox } from "@progress/kendo-vue-inputs";
import { DatePicker } from "@progress/kendo-vue-dateinputs";
import { DateTimePicker } from "@progress/kendo-vue-dateinputs";
import AppModal from "../../../../common/AppModal/AppModal.vue";
import AppSnackBar from "../../../../common/AppSnackBar/AppSnackBar.vue";
import loader from "../../../../common/AppLoader/AppLoader.vue";
import { Tooltip } from "@progress/kendo-vue-tooltip";
import Breadcrumb from "../../../../common/AppBreadCrumbs/BreadCrumbs.vue";
import {
  addVoucherCampaign,
  updateVoucherCampaign,
  getSingleVOucherCampaign,
} from "@/services/clubDetails/voucher.service";

import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardImage,
  CardActions,
  Avatar,
} from "@progress/kendo-vue-layout";
export default {
  name: "CreateVoucherWithBarcode",
  components: {
    Tooltip,
    tabstrip: TabStrip,
    tabstripTab: TabStripTab,
    kbutton: Button,
    "kendo-colorpicker": ColorPicker,
    upload: Upload,
    loader,
    "k-input": Input,
    numericTextBox: NumericTextBox,
    radiobutton: RadioButton,
    card: Card,
    cardHeader: CardHeader,
    cardBody: CardBody,
    cardTitle: CardTitle,
    cardImage: CardImage,
    cardActions: CardActions,
    checkbox: Checkbox,
    datePicker: DatePicker,
    avatar: Avatar,
    datetimepicker: DateTimePicker,
    AppModal,
    AppSnackBar,
    Breadcrumb,
  },
  data: function () {
    let currDate = new Date();
    currDate.setDate(currDate.getDate() + 7);
    return {
      itemCode: "allVoucher",
      moduleCode: "voucher",
      loader: false,
      voucherPrefix: "",
      voucherId: +this.$route.query.voucher_id || "",
      clubId: this.$route.params.id,
      isFromManageRecipients: this.$route.params.fromManageRecipients || false,
      barcodeType: "qrcode",
      selected: 0,
      voucherName: "",
      clubName: this.$route.query.club_name,
      voucherAmount: 100,
      voucherQuantity: 1,
      expiryDate: currDate,
      currDate: currDate,
      voucherType: 2,
      submitDisable: false,
      notifyMessage: "",
      notifyType: "",
      notify: false,
      isNotify: false,
      buttonName: "Save Program",
      isVoucherEmailSent: false,
      month_names_short: [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ],
    };
  },
  computed: {
    club() {
      return this.$store.getters.clubDetails;
    },
  },

  mounted() {
    if (!this.hasGetAccess()) {
      this.$store.commit("SHOW_NOTIFICATION", {
        message: "You do not have access to view campaigns.",
        type: "warning",
      });
      this.$router.push(`/club-details/${this.$route.params.id}/dashboard`);
    }
    if (this.$route.query.voucher_id) {
      this.buttonName = "Update Program";
      this.getVoucherCampaignInfo();
    }
    if (this.$route.query.voucher_name) {
      this.voucherName = this.$route.query.voucher_name;
    }
  },
  methods: {
    hasGetAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasGetPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasDeleteAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasEditAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPutPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    hasCreateAccess() {
      return this.$hasAccessToModule({
        permissionKey: "hasPostPermission",
        moduleCode: this.moduleCode,
        itemCode: this.itemCode,
        childItemCode: null,
      });
    },
    onSelect(e) {
      this.selected = e.selected;
    },
    isPastDate(date) {
      const today = new Date();
      today.setHours(0, 0, 0, 0);
      date.setHours(0, 0, 0, 0);
      return date < today;
    },
    showNotification(message, type) {
      this.notifyMessage = message;
      this.notifyType = type;
      this.isNotify = true;
      setTimeout(
        () => (
          (this.notifyMessage = ""),
          (this.notifyType = "success"),
          (this.isNotify = false)
        ),
        2000
      );
    },

    navigateToVoucherHomepage() {
      this.$router.push({
        name: "AllVoucher",
        query: {
          type: "3",
        },
      });
    },
    navigateToManageRecipients() {
      this.$router.push({
        name: "VoucherManageRecipients",
        query: {
          voucherCampaignId: +this.$route.query.voucher_id,
          club_name: this.$route.query.club_name,
          type: +this.$route.query.type,
        },
        params: {
          voucherCampaignId: +this.$route.query.voucher_id,
        },
      });
    },

    cancelButtonHandler() {
      if (this.isFromManageRecipients) {
        this.navigateToManageRecipients();
      } else {
        this.navigateToVoucherHomepage();
      }
    },

    barcodeHandleChange(e) {
      this.barcodeType = e.value;
    },

    updateExpiryDate(event) {
      this.expiryDate = event.target.value;
    },

    dataValidation() {
      function onlyLettersAndNumbers(str) {
        return /^[A-Za-z0-9]*$/.test(str);
      }

      if (!this.voucherAmount) {
        this.showNotification("Voucher amount is required.", "warning");
        return false;
      }
      if (this.isPastDate(this.expiryDate)) {
        this.showNotification("Invalid voucher expiry date.", "warning");
        return false;
      }
      if (!this.expiryDate) {
        this.showNotification("Voucher expiry date is required.", "warning");
        return false;
      }

      if (this.voucherPrefix && !onlyLettersAndNumbers(this.voucherPrefix)) {
        this.showNotification(
          "Voucher prefix should contain only letters and numbers.",
          "warning"
        );
        return false;
      }

      if (this.voucherPrefix.replace(/ /g, "").length > 6) {
        this.showNotification(
          "Voucher prefix should not be more than 6 characters.",
          "warning"
        );
        return false;
      }
      return true;
    },

    GetFormattedDate() {
      const month = this.expiryDate.getMonth() + 1;
      const day = this.expiryDate.getDate();
      const year = this.expiryDate.getFullYear();
      return day + "-" + this.month_names_short?.[month - 1] + "-" + year;
    },

    preparePayloadForVoucherCreate() {
      return {
        name: this.voucherName,
        voucherAmount: this.voucherAmount,
        // voucherQuantity: this.voucherQuantity,
        voucherPrefix: this.voucherPrefix.replace(/ /g, ""),
        expiryDate: this.expiryDate || this.currDate,
        voucherType: this.voucherType,
        clubId: this.$route.params.id,
        barcodeType: this.barcodeType,
      };
    },

    preparePayloadForVoucherUpdate() {
      const payload = {
        voucherCampaignId: this.voucherId,
        voucherAmount: this.voucherAmount,
        // voucherQuantity: this.voucherQuantity,
        expiryDate: this.expiryDate,
        clubId: this.$route.params.id,
        barcodeType: this.barcodeType,
      };

      if (!this.isVoucherEmailSent) {
        payload.voucherPrefix = this.voucherPrefix.replace(/ /g, "");
      }
      return payload;
    },

    getVoucherCampaignInfo() {
      this.loader = true;
      const payload = {
        voucherCampaignId: this.voucherId,
        clubId: this.clubId,
      };
      getSingleVOucherCampaign(payload)
        .then((response) => {
          this.loader = false;
          ;
          const {
            name,
            defaultQuantityPerAudience,
            expiryDate,
            voucherAmount,
            barcodeType,
            clubName,
            voucherPrefix,
            isVoucherEmailSent,
          } = response?.data?.data;
          this.voucherName = name;
          this.voucherAmount = voucherAmount;
          this.voucherQuantity = defaultQuantityPerAudience;
          this.expiryDate = new Date(expiryDate);
          this.barcodeType = barcodeType;
          this.clubName = clubName;
          this.voucherPrefix = voucherPrefix
            ? voucherPrefix.replace(/ /g, "")
            : "";
          this.isVoucherEmailSent = isVoucherEmailSent
            ? isVoucherEmailSent
            : this.isVoucherEmailSent;
        })
        .catch((error) => {
          this.loader = false;
          this.submitDisable = false;
          this.showNotification("Something went wrong.", "error");
          ;
        });
    },

    saveVoucher() {
      if (this.$route.query.voucher_id) {
        if (this.dataValidation()) {
          this.loader = true;
          this.submitDisable = true;
          let payload = this.preparePayloadForVoucherUpdate();
          updateVoucherCampaign(payload)
            .then(() => {
              this.loader = false;
              this.submitDisable = false;
              this.showNotification(
                "Voucher program updated successfully.",
                "success"
              );
              setTimeout(() => {
                this.cancelButtonHandler();
              }, 2000);
            })
            .catch((error) => {
              this.loader = false;
              this.submitDisable = false;
              this.showNotification(
                "Failed to update the voucher program.",
                "error"
              );
              ;
            });
        }
      } else {
        if (this.dataValidation()) {
          this.loader = true;
          this.submitDisable = true;
          let payload = this.preparePayloadForVoucherCreate();
          addVoucherCampaign(payload)
            .then(() => {
              this.loader = false;
              this.submitDisable = false;
              this.showNotification(
                "Voucher program created successfully.",
                "success"
              );
              setTimeout(() => {
                this.navigateToVoucherHomepage();
              }, 2000);
            })
            .catch((error) => {
              this.loader = false;
              this.submitDisable = false;
              this.showNotification(
                "Failed to save the voucher program.",
                "error"
              );
              ;
            });
        }
      }
    },
  },
};
</script>

<style lang=""></style>
